import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { List, ListItem, ListItemText, Box, FormControlLabel } from '@material-ui/core';
import { useState } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '0',
        paddingBottom: '0',
    },
    box: {
        fontWeight: 'bold',
        textAlign: 'right',
    },
}));

export default function CheckBoxList(props) {
    const { data = [], checkedBoxes = [], onChange } = props;
    const [checked, setChecked] = useState(checkedBoxes);
    const [checkAll, setCheckAll] = useState(false);
    const total = data.length;
    const classes = useStyles();

    React.useEffect(() => {
        setChecked(checkedBoxes);
    }, [checkedBoxes]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllToggles = () => {
        const currentState = checkAll;

        if (currentState) {
            setChecked([]);
        } else {
            //Pakeitimai 2024 12 13
            //Turėtų pasižymėti/atsižymėti visi išskyrus:
            // Algos: EDIT_A% ir GET_A%
            // Personalo: MGAMA_%
            // DK: EDIT_I0[1-3]% ir GET_I0[1-3]%
            // OpenBanking: OPB_%

            // const allData = data ? data.map((id) => id.value) : [];

            if (data) {
                const filterData = data.filter(
                    (id) =>
                        !(
                            id.value.startsWith('EDIT_A') ||
                            id.value.startsWith('GET_A') ||
                            id.value.startsWith('MGAMA_') ||
                            id.value.startsWith('OPB_') ||
                            id.value.startsWith('EDIT_I01') ||
                            id.value.startsWith('EDIT_I02') ||
                            id.value.startsWith('EDIT_I03') ||
                            id.value.startsWith('GET_I01') ||
                            id.value.startsWith('GET_I02') ||
                            id.value.startsWith('GET_I03')
                        ),
                );

                const allData = filterData.map((id) => id.value);

                setChecked(allData);
            }
        }
        setCheckAll(!currentState);
    };

    return (
        <React.Fragment>
            <Box>
                <FormControlLabel
                    control={<Checkbox checked={checkAll} onChange={handleAllToggles} />}
                    label="Žymėti pagrindinius"
                />
            </Box>
            <Box className={classes.box}>{`Pasirinkita: ${checked.length} / ${total}`}</Box>
            <List>
                {data.map((value) => {
                    const labelId = `checkbox-list-label-${value.value}`;

                    return (
                        <ListItem
                            className={classes.root}
                            key={value.value}
                            onClick={handleToggle(value.value)}
                        >
                            <Checkbox
                                id={value.value}
                                checked={checked.indexOf(value.value) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                                onChange={onChange ? onChange(checked) : undefined}
                            />
                            <ListItemText id={labelId} primary={value.label} />
                        </ListItem>
                    );
                })}
            </List>
        </React.Fragment>
    );
}

CheckBoxList.propTypes = {
    data: PropTypes.array.isRequired,
    checkedItems: PropTypes.array,
    onChange: PropTypes.func,
};
